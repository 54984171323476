import {
    InverseLink,
    Link,
    MonochromeLink,
    SubtleInverseLink,
    SubtleLink,
    SubtleMonochromeLink,
    SubtleWeakLink,
    WeakLink,
} from '@liveauctioneers/hammer-ui-core/link';
import { Link as RRLink, useHref, useLinkClickHandler } from 'react-router-dom';

import React from 'react';

type Props = Omit<React.ComponentProps<typeof Link> & React.ComponentProps<typeof RRLink>, 'href'>;

/**
 * NOTE:
 * If you are creating a link that does not route (goes to an external site) please use the vanilla hammer ui link components
 */
export const RouteLink = React.forwardRef<HTMLAnchorElement, Props>((props: Props, ref) => {
    const { onClick, replace = false, state = null, target = '', to } = props;
    const href = useHref(to);
    const handleClick = useLinkClickHandler(to, {
        replace,
        state,
        target,
    });
    return (
        <Link
            {...props}
            href={href}
            onClick={(event) => {
                onClick?.(event);
                if (!event.defaultPrevented) {
                    handleClick(event);
                }
            }}
            ref={ref}
            target={target}
        />
    );
});

/**
 * NOTE:
 * If you are creating a link that does not route (goes to an external site) please use the vanilla hammer ui link components
 */
export const WeakRouteLink = React.forwardRef<HTMLAnchorElement, Props>((props: Props, ref) => {
    const { onClick, replace = false, state = null, target = '', to } = props;
    const href = useHref(to);
    const handleClick = useLinkClickHandler(to, {
        replace,
        state,
        target,
    });
    return (
        <WeakLink
            {...props}
            href={href}
            onClick={(event) => {
                onClick?.(event);
                if (!event.defaultPrevented) {
                    handleClick(event);
                }
            }}
            ref={ref}
            target={target}
        />
    );
});

/**
 * NOTE:
 * If you are creating a link that does not route (goes to an external site) please use the vanilla hammer ui link components
 */
export const MonochromeRouteLink = React.forwardRef<HTMLAnchorElement, Props>((props: Props, ref) => {
    const { onClick, replace = false, state = null, target = '', to } = props;
    const href = useHref(to);
    const handleClick = useLinkClickHandler(to, {
        replace,
        state,
        target,
    });
    return (
        <MonochromeLink
            {...props}
            href={href}
            onClick={(event) => {
                onClick?.(event);
                if (!event.defaultPrevented) {
                    handleClick(event);
                }
            }}
            ref={ref}
            target={target}
        />
    );
});

/**
 * NOTE:
 * If you are creating a link that does not route (goes to an external site) please use the vanilla hammer ui link components
 */
export const InverseRouteLink = React.forwardRef<HTMLAnchorElement, Props>((props: Props, ref) => {
    const { onClick, replace = false, state = null, target = '', to } = props;
    const href = useHref(to);
    const handleClick = useLinkClickHandler(to, {
        replace,
        state,
        target,
    });
    return (
        <InverseLink
            {...props}
            href={href}
            onClick={(event) => {
                onClick?.(event);
                if (!event.defaultPrevented) {
                    handleClick(event);
                }
            }}
            ref={ref}
            target={target}
        />
    );
});

/**
 * NOTE:
 * If you are creating a link that does not route (goes to an external site) please use the vanilla hammer ui link components
 */
export const SubtleRouteLink = React.forwardRef<HTMLAnchorElement, Props>((props: Props, ref) => {
    const { onClick, replace = false, state = null, target = '', to } = props;
    const href = useHref(to);
    const handleClick = useLinkClickHandler(to, {
        replace,
        state,
        target,
    });
    return (
        <SubtleLink
            {...props}
            href={href}
            onClick={(event) => {
                onClick?.(event);
                if (!event.defaultPrevented) {
                    handleClick(event);
                }
            }}
            ref={ref}
            target={target}
        />
    );
});

/**
 * NOTE:
 * If you are creating a link that does not route (goes to an external site) please use the vanilla hammer ui link components
 */
export const SubtleWeakRouteLink = React.forwardRef<HTMLAnchorElement, Props>((props: Props, ref) => {
    const { onClick, replace = false, state = null, target = '', to } = props;
    const href = useHref(to);
    const handleClick = useLinkClickHandler(to, {
        replace,
        state,
        target,
    });
    return (
        <SubtleWeakLink
            {...props}
            href={href}
            onClick={(event) => {
                onClick?.(event);
                if (!event.defaultPrevented) {
                    handleClick(event);
                }
            }}
            ref={ref}
            target={target}
        />
    );
});

/**
 * NOTE:
 * If you are creating a link that does not route (goes to an external site) please use the vanilla hammer ui link components
 */
export const SubtleMonochromeRouteLink = React.forwardRef<HTMLAnchorElement, Props>((props: Props, ref) => {
    const { onClick, replace = false, state = null, target = '', to } = props;
    const href = useHref(to);
    const handleClick = useLinkClickHandler(to, {
        replace,
        state,
        target,
    });
    return (
        <SubtleMonochromeLink
            {...props}
            href={href}
            onClick={(event) => {
                onClick?.(event);
                if (!event.defaultPrevented) {
                    handleClick(event);
                }
            }}
            ref={ref}
            target={target}
        />
    );
});

/**
 * NOTE:
 * If you are creating a link that does not route (goes to an external site) please use the vanilla hammer ui link components
 */
export const SubtleInverseRouteLink = React.forwardRef<HTMLAnchorElement, Props>((props: Props, ref) => {
    const { onClick, replace = false, state = null, target = '', to } = props;
    const href = useHref(to);
    const handleClick = useLinkClickHandler(to, {
        replace,
        state,
        target,
    });
    return (
        <SubtleInverseLink
            {...props}
            href={href}
            onClick={(event) => {
                onClick?.(event);
                if (!event.defaultPrevented) {
                    handleClick(event);
                }
            }}
            ref={ref}
            target={target}
        />
    );
});
