import { BodySecondary } from '@liveauctioneers/hammer-ui-core/text';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import {
    getItemModelBuyNowAvailable,
    getItemModelIsLocked,
    getItemModelIsPassed,
    getItemModelIsSold,
    getItemModelSellerId,
    getItemModelSellerName,
} from '@/redux/modules/item/summary/itemSummary.selectors';
import { getLiveItemStatusOpen } from '@/redux/modules/liveItemStatus';
import { getSellerUrl } from '@/utils/urls';
import { ItemCardNavigationTrigger } from '@/utils/analytics';
import { useAppSelector } from '@/redux/hooks';
import { WeakRouteLink } from '@/components/RouteLinks/RouteLinks';
import ItemCardShortDate from '@/components/GridItemCard/Card/components/ItemCardShortDate/ItemCardShortDate';
import styled from 'styled-components';

export type CardInfoProps = {
    itemId: number;
    onNavigate?: (trigger: ItemCardNavigationTrigger) => void;
};

const CardInfo = ({ itemId, onNavigate }: CardInfoProps) => {
    const buyNowAvailable = useAppSelector((state) => getItemModelBuyNowAvailable(state, itemId));
    const isLotOpen = useAppSelector((state) => getLiveItemStatusOpen(state, itemId));
    const isPassed = useAppSelector((state) => getItemModelIsPassed(state, itemId));
    const isSold = useAppSelector((state) => getItemModelIsSold(state, itemId));
    const isLocked = useAppSelector((state) => getItemModelIsLocked(state, itemId));
    const sellerId = useAppSelector((state) => getItemModelSellerId(state, itemId));
    const sellerName = useAppSelector((state) => getItemModelSellerName(state, itemId));

    const isPending = isLocked && !isPassed && !isSold && !isLotOpen;

    return (
        <CardInfoSection>
            <StyledDateInfo>
                {buyNowAvailable ? (
                    <BodySecondary color="secondary">
                        <FormattedMessage id="buy_now" />
                    </BodySecondary>
                ) : (
                    <ItemCardShortDate
                        itemId={itemId}
                        onNavigate={onNavigate}
                        showOnlyYear={isSold || isPassed || isPending}
                    />
                )}
            </StyledDateInfo>
            <StyledAuctionHouseName
                data-testid="cardInfoAuctionHouseName"
                onClick={() => onNavigate?.(ItemCardNavigationTrigger.HouseNameClick)}
                to={getSellerUrl(sellerId, sellerName)}
            >
                <BodySecondary color="secondary">{sellerName}</BodySecondary>
            </StyledAuctionHouseName>
        </CardInfoSection>
    );
};

export default CardInfo;

const CardInfoSection = styled.section`
    grid-area: iteminfo;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs};
`;

const StyledDateInfo = styled.span`
    line-height: 18px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        width: 100%;
        white-space: nowrap;
    }

    @media (max-width: 375px) {
        width: unset;
    }
`;

const StyledAuctionHouseName = styled(WeakRouteLink)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 18px;
    text-align: right;
    max-width: 150px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        min-width: 94px;
        max-width: 94px;
    }

    &:hover > span {
        color: ${({ theme }) => theme.colors.blue100};
        text-decoration: underline;
    }
`;
