import { BodySecondary } from '@liveauctioneers/hammer-ui-core/text';
import { BuyNowStatus } from '@/types/item/ItemSummary';
import { cn } from '@liveauctioneers/hammer-ui-theme/cn';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { getItemSummary } from '@/redux/modules/item/summary/itemSummary.selectors';
import { getLiveCatalogCurrentItemIndex } from '@/redux/modules/console';
import { ItemCardNavigationTrigger } from '@/utils/analytics';
import { RedTextButtonRight } from '@liveauctioneers/caterwaul-components/lib/Text/Text';
import { useAppSelector } from '@/redux/hooks';
import BidConsoleOpener from '@/components/BidConsoleOpener/BidConsoleOpener';
import CatalogDate from '@/components/CatalogDate/CatalogDate';
import React from 'react';

export type ItemCardShortDateProps = {
    allowBidding?: boolean;
    className?: string;
    forceDate?: boolean;
    itemId: number;
    // Here for disabling hover styles on parent when component is an active one
    onMouseEnter?: React.MouseEventHandler<HTMLSpanElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLSpanElement>;
    onNavigate?: (trigger: ItemCardNavigationTrigger) => void;
    showDateWithoutYear?: boolean;
    showOnlyYear?: boolean;
    usePrimary?: boolean;
};

/**
 * This component will either return a short format date, or the text "Available to Buy Now"
 * if the item is available to buy now. If it can be bid on now, show that instead
 */
export default function ItemCardShortDate({
    allowBidding = false,
    className,
    forceDate = false,
    itemId,
    onMouseEnter,
    onMouseLeave,
    onNavigate,
    showDateWithoutYear = false,
    showOnlyYear = false,
    usePrimary = false,
}: ItemCardShortDateProps) {
    const {
        buyNowStatus,
        catalogId,
        catalogStatus,
        index,
        isCatalogOnly,
        isPassed,
        isSold,
        isTimedPlusAuction,
        saleStartTs,
    } = useAppSelector((state) => getItemSummary(state, itemId));

    const isDone = catalogStatus === 'done';
    const currentItemIndex = useAppSelector((state) => getLiveCatalogCurrentItemIndex(state, catalogId));
    const lotsAway = index - currentItemIndex;

    if (isTimedPlusAuction && !forceDate) {
        return null;
    }

    if (Date.now() > saleStartTs * 1000 && !isSold && !isPassed && !isCatalogOnly) {
        return (
            <BidConsoleOpener
                catalogId={catalogId}
                onNavigate={() => onNavigate?.(ItemCardNavigationTrigger.BidLiveClick)}
            >
                <RedTextButtonRight
                    className={cn('flex items-center', className)}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    role="button"
                >
                    {lotsAway > 0 && allowBidding ? (
                        lotsAway === 1 ? (
                            <FormattedMessage id="itemBidding.lotAway_short" />
                        ) : (
                            <FormattedMessage
                                id="itemBidding.lotsAway_short"
                                values={{ lotsAway }}
                            />
                        )
                    ) : (
                        <FormattedMessage id="itemBidding.liveNow" />
                    )}
                </RedTextButtonRight>
            </BidConsoleOpener>
        );
    }

    if (buyNowStatus === BuyNowStatus.Available && isDone) {
        return (
            <BodySecondary
                className={className}
                color="critical"
                key={itemId}
            >
                <FormattedMessage id="available_to_buy_now" />
            </BodySecondary>
        );
    }

    return (
        <BodySecondary
            className={className}
            key={itemId}
        >
            <CatalogDate
                date={saleStartTs}
                showDateWithoutYear={showDateWithoutYear}
                showOnlyYear={showOnlyYear}
                usePrimary={usePrimary}
            />
        </BodySecondary>
    );
}
