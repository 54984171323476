import { useIsOnCategorySearchOrPriceGuidePage } from '@/hooks/useIsOnCategorySearchOrPriceGuidePage';
import ImageRow from '@/components/ItemCard/ImageRow';
import ItemCardSaveIcon from '@/components/GridItemCard/Card/components/ItemCardSaveIcon/ItemCardSaveIcon';

export type CardImageProps = {
    applyDimensionToUrl?: boolean;
    canShowEasyShipping: boolean;
    className?: string;
    dimension?: number;
    enterLinkHoverState: () => void;
    imageQuality?: number;
    isMobile: boolean;
    itemId: number;
    lazyLoadImage: boolean;
    leaveLinkHoverState: () => void;
    onNavigate?: () => void;
};

const CardImage = ({
    applyDimensionToUrl,
    canShowEasyShipping,
    className,
    dimension,
    enterLinkHoverState,
    imageQuality,
    isMobile,
    itemId,
    lazyLoadImage,
    leaveLinkHoverState,
    onNavigate,
}: CardImageProps) => {
    const showNewFavoriteButton = useIsOnCategorySearchOrPriceGuidePage();

    return (
        <section className="relative">
            <span className="absolute right-2 top-2 z-[3]">
                <ItemCardSaveIcon
                    canShowEasyShipping={canShowEasyShipping}
                    itemId={itemId}
                    labeled={!isMobile && showNewFavoriteButton}
                />
            </span>
            <ImageRow
                applyDimensionToUrl={applyDimensionToUrl}
                className={`grid-area-itemimage ${className}`}
                dimension={dimension}
                imageQuality={imageQuality}
                itemId={itemId}
                lazyLoadImage={lazyLoadImage}
                onMouseEnter={enterLinkHoverState}
                onMouseLeave={leaveLinkHoverState}
                onNavigate={onNavigate}
                showImageWithHover={!isMobile}
            />
        </section>
    );
};

export default CardImage;
